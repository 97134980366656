import React, { useEffect } from 'react';
import { MapContainer, useMap, useMapEvents } from 'react-leaflet';
import { LatLng, LatLngBounds } from 'leaflet';
import SourceTilesCustomLayer from './SourceTilesCustomLayer';

interface SourceTilesMapProps {
    center: LatLng;
    tilesSourcesMap: Map<string, string>;
    bounds: LatLngBounds | null;
    zoom: number;
    onBoundsChange: (bounds: LatLngBounds) => void;
}

const SourceTilesMap = ({ center, zoom, bounds, onBoundsChange, tilesSourcesMap }: SourceTilesMapProps) => {
    return (
        <div>
            <MapContainer
                center={center}
                maxZoom={zoom}
                minZoom={zoom}
                zoom={zoom}
                style={{ height: '100vh', width: '100%' }}
            >
                <SourceTilesCustomLayer tilesSourcesMap={tilesSourcesMap} />
                <MapBoundsChangeHandler bounds={bounds} onBoundsChange={onBoundsChange} />
            </MapContainer>
        </div>
    );
};

interface MapBoundsProps {
    bounds: LatLngBounds | null;
    onBoundsChange: (bounds: LatLngBounds) => void;
}

const MapBoundsChangeHandler = ({ bounds, onBoundsChange }: MapBoundsProps) => {
    const map = useMap();

    useMapEvents({
        dragend: () => {
            onBoundsChange(map.getBounds());
        },
    });

    useEffect(() => {
        if (bounds) {
            const center = bounds.getCenter(); // Calculate the center of the bounds
            map.setView(center, map.getZoom());
        } else {
            onBoundsChange(map.getBounds());
        }
    }, [map, bounds]);

    return null;
};

export default SourceTilesMap;
