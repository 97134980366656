import React, { useEffect, useState } from 'react';
import { LatLng, LatLngBounds } from 'leaflet';
import centroid from '@turf/centroid';
import { MultiPolygon, Polygon } from '@turf/turf';
import Box from '@mui/material/Box';
import SourceTilesMap from './SourceTilesMap';
import StreetViewWidget from './StreetViewWidget';
import { Feature } from '@turf/helpers';

interface SourceTilesViewerProps {
    tilesSourcesMap: Map<string, string>;
    viewAreaPolygon: Feature<Polygon | MultiPolygon>;
}

const SourceTilesViewer = ({ tilesSourcesMap, viewAreaPolygon }: SourceTilesViewerProps) => {
    const [bounds, setBounds] = useState<LatLngBounds | null>(null);
    const onBoundsChange = (bounds: LatLngBounds) => {
        setBounds(bounds);
    };

    const [center, setCenter] = useState<LatLng | null>(null);

    useEffect(() => {
        if (viewAreaPolygon && viewAreaPolygon.geometry.coordinates) {
            const centroidFeature = centroid(viewAreaPolygon);
            const [lng, lat] = centroidFeature.geometry.coordinates;
            setCenter(new LatLng(lat, lng));
        }
    }, [viewAreaPolygon]);

    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100vh' }}>
            {center && (
                <SourceTilesMap
                    onBoundsChange={onBoundsChange}
                    bounds={bounds}
                    tilesSourcesMap={tilesSourcesMap}
                    center={center}
                    zoom={15}
                />
            )}
            {bounds && center && (
                <StreetViewWidget
                    bounds={bounds}
                    polygon={viewAreaPolygon}
                    center={center}
                    zoom={10}
                    onBoundsChange={onBoundsChange}
                />
            )}
        </Box>
    );
};

export default SourceTilesViewer;
