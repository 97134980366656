import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L, { LatLngBounds } from 'leaflet';

interface MapDraggableRectangleProps {
    bounds: LatLngBounds;
    onBoundsChange: (newBounds: LatLngBounds) => void;
}

const MapDraggableRectangle = ({ bounds, onBoundsChange }: MapDraggableRectangleProps) => {
    const map = useMap();
    const rectangleRef = useRef<L.Rectangle | null>(null);

    useEffect(() => {
        const rectangle = L.rectangle(bounds, { fillColor: '#f94f4b', color: '#f94f4b' });
        rectangle.addTo(map);
        rectangleRef.current = rectangle;
        // Make the rectangle draggable
        const element = rectangle.getElement();
        if (element) {
            const draggable = new L.Draggable(element as HTMLElement);
            draggable.enable();

            // Update bounds on drag
            draggable.on('dragend', () => {
                if (!rectangleRef.current || !map) return;

                // @ts-ignore
                const { x: offsetX, y: offsetY } = draggable._newPos;

                // Calculate the bounds shift by finding the distance moved
                const originalBounds = rectangle.getBounds();
                const originalSouthWest = map.latLngToLayerPoint(originalBounds.getSouthWest());
                const originalNorthEast = map.latLngToLayerPoint(originalBounds.getNorthEast());

                // Calculate new bounds by applying the same offset to corners
                const newSouthWestPoint = L.point(
                    originalSouthWest.x + offsetX,
                    originalSouthWest.y + offsetY,
                );
                const newNorthEastPoint = L.point(
                    originalNorthEast.x + offsetX,
                    originalNorthEast.y + offsetY,
                );

                const newSouthWest = map.layerPointToLatLng(newSouthWestPoint);
                const newNorthEast = map.layerPointToLatLng(newNorthEastPoint);

                // Set new bounds to the rectangle
                const newBounds = L.latLngBounds(newSouthWest, newNorthEast);

                onBoundsChange(newBounds);
            });
        }

        return () => {
            map.removeLayer(rectangle); // Clean up when component unmounts
        };
    }, [map, bounds]);

    return null;
};

export default MapDraggableRectangle;
