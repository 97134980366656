import { JSX } from 'react';

export interface LatLng {
    lat: number;
    lng: number;
}

export interface Tile {
    x: number;
    y: number;
    z: number;
    key: string;
    source: string;
}

export enum ToastType {
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS',
}

export interface IToast {
    message: string | JSX.Element;
    type: ToastType;
}

export enum ZoneStatus {
    CREATED = 'CREATED',
    PROCESSING = 'PROCESSING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    DEPRECATED = 'DEPRECATED',
    UPGRADING = 'UPGRADING',
}

export enum AreaGeometryType {
    Circle = 'Circle',
    Polygon = 'Polygon',
}

export interface CircleShape {
    center: LatLng;
    radius: number;
}

export interface PolygonShape {
    coordinates: LatLng[];
}

export interface Zone {
    _id: ObjectId;
    status: ZoneStatus;
    createdAt: string;
    circleAreaGeometry?: CircleShape;
    polygonAreaGeometry?: PolygonShape;
    models: any;
    error?: string;
    name: string;
}

export interface TempZone {
    circleAreaGeometry?: CircleShape;
    polygonAreaGeometry?: PolygonShape;
}

export interface ObjectId {
    $oid: string;
}

export interface Device {
    deviceId: string;
    baseImageS3Link?: string;
    status?: string;
    commitHash?: string;
    deviceSerial?: number;
    zymkeySerial?: string;
    rpiSerial?: string;
    username?: string;
    hostname?: string;
    sshPort?: number;
    accessPointSSID?: string;
    vpnProfile?: string;
    vpnIPAddress?: string;
    vpnPubkey?: string;
    bundleDevicePubkey?: string;
    bundleCloudPubkey?: string;
    bundleCloudPrivkey?: string;
    mapS3Link?: string;
    secureBootPrivkey?: string;
    zymkeyPubkey?: string;
    deviceSSHPubkey?: string;
    deviceSSHPubkeyType?: string;
    deviceSSHPubkeyHash?: string;
    systemVersion?: string;
    createdAt?: string;
    updatedAt?: string;
    organization?: string;
    organizationDetails?: Organization;
}

export interface DeviceSecrets {
    userPassword: string;
    accessPointPassword: string;
}

export interface Organization {
    name: string;
    labelColor: string;
}

export interface DeviceWithSticks extends Device {
    activationSticks: ActivationStick[];
}

export interface ActivationStick {
    activationStickId: string;
    deviceId: string;
    deviceSerial: number;
    partitionUUID: string;
    rpiSerial: string;
    bundleS3Link: string;
    systemVersion: string;
}

export interface SystemConfiguration {
    systemVersion: string;
    configuration: {
        applicationVersion: string;
        mapVersion: string;
        containerVersion: string;
    };
}

export enum UserRoles {
    ADMINS = 'admins',
    FARM = 'farm',
}

export interface TileSourcesResult {
    presigned_url: string;
    geometry: LatLng[] | LatLng[][];
}
