import { fetchAuthSession } from 'aws-amplify/auth';
import { UserRoles } from '../interfaces';

export const getUserRoles = async (): Promise<UserRoles[]> => {
    try {
        const session = await fetchAuthSession();
        const token = session.tokens!.idToken!;
        const roles = (token.payload['cognito:groups'] || []) as UserRoles[];
        return roles;
    } catch (error) {
        console.error('Error getting cognito session:', error);
        throw new Error('Error getting cognito session');
    }
};
