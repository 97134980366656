import { useNavigate } from 'react-router-dom';
import {
    setUserLoading,
    selectUser,
    selectUserRoles,
    selectUserLoading,
    setUser,
    setUserRoles,
} from '../store/user.reducer';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { signOut, signIn, getCurrentUser } from 'aws-amplify/auth';
import { getUserRoles } from '../utils/getUserRoles';

export const useCognitoUser = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const isUserLoading = useAppSelector(selectUserLoading);
    const user = useAppSelector(selectUser);
    const userRoles = useAppSelector(selectUserRoles);

    const logout = async () => {
        await signOut();
        dispatch(setUser(undefined));
        dispatch(setUserRoles(undefined));
        navigate('/login');
    };

    const logIn = (username: string, password: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch(setUserLoading(true));
                await signIn({
                    username,
                    password,
                });
                const userRoles = await getUserRoles();
                dispatch(setUser(await getCurrentUser()));
                dispatch(setUserRoles(userRoles));
                navigate('app');
                resolve(undefined);
            } catch (error) {
                console.error('Authentication failed', error);
                reject(error);
            } finally {
                dispatch(setUserLoading(false));
            }
        });
    };

    return {
        user,
        userRoles,
        loading: isUserLoading,
        logIn,
        logout,
    };
};
