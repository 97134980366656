import { LeafletMouseEvent } from 'leaflet';
import { useMapEvents } from 'react-leaflet';

interface MapEventsHandlerProps {
    onClick: (e: LeafletMouseEvent) => void;
}

const MapEventsHandler = ({ onClick }: MapEventsHandlerProps) => {
    useMapEvents({
        click: onClick,
    });

    return null;
};

export default MapEventsHandler;
