interface Config {
    API_URL: string;
    S3_DATA: {
        TilesBucketName: string;
        TilesBucketRegion: string;
    };
    POOL_DATA: {
        UserPoolId: string;
        ClientId: string;
        IdentityPoolId: string;
    };
}

const config: Config = {
    API_URL: process.env.REACT_APP_API_URL as string,
    S3_DATA: {
        TilesBucketName: process.env.REACT_APP_TILES_BUCKET_NAME as string,
        TilesBucketRegion: process.env.REACT_APP_TILES_BUCKET_REGION as string,
    },
    POOL_DATA: {
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
        IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID as string,
    },
};

export default config;
