import axios from 'axios';
import config from '../config';
import { LatLng, TileSourcesResult } from '../interfaces';

interface RetrieveTilesSourcesParams {
    geometry?: LatLng[];
    processingEventName?: string;
    processingEventNames?: string[];
}

export const retrieveTilesSources = (params: RetrieveTilesSourcesParams): Promise<TileSourcesResult> => {
    const url = `${config.API_URL}/tiles-sources`;

    return axios.post(url, params).then((response: any) => {
        return response.data.data;
    });
};
