import React, { useEffect, useState } from 'react';
import { retrieveTilesSources } from '../api/retrieveTilesSources';
import { LatLng, ToastType } from '../interfaces';
import SourceTilesViewer from '../Components/SourceTilesViewer/SourceTilesViewer';
import { getTilesSourcesMap } from '../Components/SourceTilesViewer/getTilesSourcesMap';
import { getTilesSourcesData } from '../api/getTilesSourcesData';
import { useToastMessage } from '../hooks/useToastMessage';
import ToastWrapper from '../Components/ToastWrapper';
import { useLocation } from 'react-router-dom';
import { polygon, Polygon, MultiPolygon, union } from '@turf/turf';
import { Feature } from '@turf/helpers/dist/js/lib/geojson';

const TilesViewer = () => {
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();
    const location = useLocation();

    const [viewAreaPolygon, setViewAreaPolygon] = useState<Feature<Polygon | MultiPolygon>>();
    const [tilesSourcesMap, setTilesSourcesMap] = useState<Map<string, string> | null>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const polygonParam = searchParams.get('polygon');
        const eventNameParam = searchParams.get('processingEventName');
        const eventNamesParam = searchParams.get('processingEventNames');

        if (!polygonParam && !eventNameParam && !eventNamesParam) {
            console.log('polygonParam or eventNameParam or eventNamesParam should be provided in the URL');
            setToastMessage({
                message: `Не вказано полігон або ім'я події обробки в URL.`,
                type: ToastType.WARNING,
            });
        }

        (async () => {
            try {
                const { presigned_url, geometry } = await retrieveTilesSources({
                    geometry: polygonParam ? JSON.parse(decodeURIComponent(polygonParam)) : undefined,
                    processingEventName: eventNameParam ? decodeURIComponent(eventNameParam) : undefined,
                    processingEventNames: eventNamesParam
                        ? JSON.parse(decodeURIComponent(eventNamesParam))
                        : undefined,
                });
                const response = await getTilesSourcesData(presigned_url);
                setTilesSourcesMap(getTilesSourcesMap(response));
                if (geometry[0] instanceof Array) {
                    const polygons = (geometry as LatLng[][]).map((ring) => {
                        const coordinates = ring.map((point) => [point.lng, point.lat]);
                        coordinates.push(coordinates[0]);
                        return polygon([coordinates]);
                    });
                    const multiPolygonGeoJSON = polygons.reduce((merged, current) => {
                        return merged ? union(merged, current) : current;
                    }, null as Feature<Polygon | MultiPolygon> | null);
                    setViewAreaPolygon(multiPolygonGeoJSON as Feature<MultiPolygon>);
                } else {
                    const coordinates = (geometry as LatLng[]).map((point) => [point.lng, point.lat]);
                    coordinates.push(coordinates[0]);
                    const polygonGeoJSON = polygon([coordinates]);
                    setViewAreaPolygon(polygonGeoJSON);
                }
            } catch (e) {
                console.error(e);
                setToastMessage({
                    message: `Не вказано отримати тайлів.`,
                    type: ToastType.WARNING,
                });
            }
        })();
    }, [location.search]);

    return (
        <>
            {tilesSourcesMap && viewAreaPolygon ? (
                <SourceTilesViewer tilesSourcesMap={tilesSourcesMap} viewAreaPolygon={viewAreaPolygon} />
            ) : (
                <>Getting tiles list... </>
            )}
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </>
    );
};

export default TilesViewer;
