import React, { useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';

interface MapWidgetResizableAreaProps {
    children: React.ReactNode;
    onChange: (state: MapWidgetResizableAreaState) => void;
}

export interface MapWidgetResizableAreaState {
    x: number;
    y: number;
    width: number;
    height: number;
}

const MapWidgetResizableArea = ({ children, onChange }: MapWidgetResizableAreaProps) => {
    const style = {
        zIndex: 1000,
        background: '#f0f0f0',
    };

    const defaultWidth = 300;
    const defaultHeight = 200;
    const pageOffset = 10;
    const widgetStateName = 'MapWidgetResizableAreaState';
    const lastState: MapWidgetResizableAreaState = localStorage.getItem(widgetStateName)
        ? JSON.parse(localStorage.getItem(widgetStateName)!)
        : null;
    const width = lastState?.width || defaultWidth;
    const height = lastState?.height || defaultHeight;

    const defaultRndState = {
        width,
        height,
        x: window.document.body.offsetWidth - width - pageOffset,
        y: window.document.body.offsetHeight - height - pageOffset,
    };

    const [rndState, setRndState] = useState<MapWidgetResizableAreaState>(defaultRndState);

    useEffect(() => {
        const onResize = () => {
            const newState = {
                x: window.document.body.offsetWidth - rndState.width - pageOffset,
                y: window.document.body.offsetHeight - rndState.height - pageOffset,
            };

            setRndState((prevState: any) => {
                return {
                    ...prevState,
                    ...newState,
                };
            });
        };

        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [rndState]);

    useEffect(() => {
        onChange(rndState);
        localStorage.setItem(widgetStateName, JSON.stringify(rndState));
    }, [rndState]);

    return (
        <Rnd
            style={style}
            size={{ width: rndState.width, height: rndState.height }}
            position={{ x: rndState.x, y: rndState.y }}
            disableDragging={true}
            enableResizing={{ top: true, left: true }} // Enable resizing on all sides
            onResize={(e, direction, ref, delta, position) => {
                const newState = {
                    width: parseInt(ref.style.width.replace('px', '')),
                    height: parseInt(ref.style.height.replace('px', '')),
                    ...position,
                };

                setRndState((prevState: any) => {
                    return {
                        ...prevState,
                        ...newState,
                    };
                });
            }}
        >
            {children}
        </Rnd>
    );
};

export default MapWidgetResizableArea;
