import React, { useEffect } from 'react';
import './App.css';
import Layout from './Components/Layout';
import { store } from './store/store';
import { Provider } from 'react-redux';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import './api/interceptors';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './Components/Login';
import { ProtectedRoute } from './Components/ProtectedRoute';
import { UserRoles } from './interfaces';
import { useAppDispatch } from './store/hooks';
import { loadCurrentUser } from './store/user.reducer';
import TilesViewer from './routes/TilesViewer';

function RoutesWrapper() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadCurrentUser());
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="app/*"
                    element={
                        <ProtectedRoute allowedRoles={[UserRoles.ADMINS, UserRoles.FARM]}>
                            <Layout />
                        </ProtectedRoute>
                    }
                />
                <Route path="login" element={<Login />} />
                <Route
                    path="tiles-viewer"
                    element={
                        <ProtectedRoute allowedRoles={[UserRoles.ADMINS]}>
                            <TilesViewer />
                        </ProtectedRoute>
                    }
                />
                <Route path="*" element={<Navigate to="app" replace />} />
            </Routes>
        </BrowserRouter>
    );
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <RoutesWrapper />
            </Provider>
        </ThemeProvider>
    );
}

export default App;
