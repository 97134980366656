import React, { useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { LatLng, LatLngBounds, Map as LeafletMap } from 'leaflet';
import { Card } from '@mui/material';
import MapEventsHandler from './MapEventsHandler';
import MapSourcesRestrictionPolygon from './MapSourcesRestrictionPolygon';
import MapDraggableRectangle from './MapDraggableRectangle';
import MapWidgetResizableArea, { MapWidgetResizableAreaState } from './MapWidgetResizebleArea';
import { Feature } from '@turf/helpers';
import { MultiPolygon, Polygon } from '@turf/turf';

// Calculate new bounds based on click position
const calculateNewBoundsFromClick = (previousBounds: LatLngBounds, clickLatLng: LatLng) => {
    // @ts-ignore
    const prevNorthEast = previousBounds._northEast;
    // @ts-ignore
    const prevSouthWest = previousBounds._southWest;

    // Calculate the center of the previous bounds
    const prevCenterLat = (prevNorthEast.lat + prevSouthWest.lat) / 2;
    const prevCenterLng = (prevNorthEast.lng + prevSouthWest.lng) / 2;

    // Calculate the offset between the previous center and the click location
    const latOffset = clickLatLng.lat - prevCenterLat;
    const lngOffset = clickLatLng.lng - prevCenterLng;

    // Calculate new bounds by applying the offset to each corner of the previous bounds
    const newNorthEast = {
        lat: prevNorthEast.lat + latOffset,
        lng: prevNorthEast.lng + lngOffset,
    };
    const newSouthWest = {
        lat: prevSouthWest.lat + latOffset,
        lng: prevSouthWest.lng + lngOffset,
    };

    // Create and return the new bounds
    return new LatLngBounds(newSouthWest, newNorthEast);
};

interface StreetViewWidgetProps {
    center: LatLng;
    bounds: LatLngBounds;
    polygon: Feature<Polygon | MultiPolygon>;
    onBoundsChange: (bounds: LatLngBounds) => void;
    zoom: number;
}

const StreetViewWidget = ({ bounds, center, polygon, zoom, onBoundsChange }: StreetViewWidgetProps) => {
    const mapRef = useRef(null);
    const [rndState, setRndState] = useState<MapWidgetResizableAreaState>();

    return (
        <MapWidgetResizableArea
            onChange={(state) => {
                if (mapRef.current) {
                    const map = mapRef.current as LeafletMap;
                    map.invalidateSize();
                }
                setRndState(state);
            }}
        >
            {rndState && (
                <Card style={{ width: `${rndState.width}px`, height: `${rndState.height}px` }}>
                    <MapContainer
                        ref={mapRef}
                        center={center}
                        zoom={zoom}
                        style={{ width: `100%`, height: `100%` }}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <MapSourcesRestrictionPolygon polygon={polygon} />
                        <MapDraggableRectangle bounds={bounds} onBoundsChange={onBoundsChange} />
                        <MapEventsHandler
                            onClick={(e) => {
                                onBoundsChange(calculateNewBoundsFromClick(bounds, e.latlng));
                            }}
                        />
                    </MapContainer>
                </Card>
            )}
        </MapWidgetResizableArea>
    );
};

export default StreetViewWidget;
