import ListItem from '@mui/material/ListItem';
import { IconButton, ListItemButton, Tooltip } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { memo } from 'react';
import { useAppDispatch } from '../store/hooks';
import { setSelectedZone } from '../store/zones.reducer';
import { ZoneStatusBadge } from './ZoneStatusBadge';
import { Zone, ZoneStatus } from '../interfaces';
import { Badge } from './Badge';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { navigateToTilesViewer } from './SourceTilesViewer/navigateToTilesViewer';

interface ZonesHistoryItemProps {
    zone: Zone;
}

export const ZoneHistoryItem = memo(({ zone }: ZonesHistoryItemProps) => {
    const dispatch = useAppDispatch();

    return (
        <ListItem
            disablePadding
            secondaryAction={
                <>
                    {zone.status === ZoneStatus.COMPLETED && (
                        <span>
                            {zone.polygonAreaGeometry && (
                                <Tooltip title="Переглянути сорси зони" placement="top">
                                    <IconButton
                                        size="small"
                                        onClick={async () => {
                                            navigateToTilesViewer({
                                                processingEventName: zone.name,
                                            });
                                        }}
                                        edge="end"
                                        aria-label="delete"
                                    >
                                        <ZoomInMapIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </span>
                    )}
                </>
            }
        >
            <ListItemButton
                onClick={() => {
                    dispatch(setSelectedZone(zone));
                }}
                dense
            >
                <div>
                    <ListItemText
                        primary={`${zone.name}`}
                        secondary={
                            <>
                                {zone.models &&
                                    Object.keys(zone.models).map((modelId, index) => {
                                        return (
                                            <Badge
                                                key={index}
                                                className={'mr-1 mb-1'}
                                                backgroundColor={'rgb(75 130 249)'}
                                            >
                                                {modelId}
                                            </Badge>
                                        );
                                    })}
                                <span style={{ display: 'block' }}>
                                    <ZoneStatusBadge zone={zone} />
                                </span>
                            </>
                        }
                    />
                </div>
            </ListItemButton>
        </ListItem>
    );
});
