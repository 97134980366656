import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { Feature, MultiPolygon, Polygon } from '@turf/helpers';

interface MapSourcesRestrictionPolygonProps {
    polygon: Feature<Polygon | MultiPolygon>;
}

const MapSourcesRestrictionPolygon = ({ polygon }: MapSourcesRestrictionPolygonProps) => {
    const map = useMap();

    useEffect(() => {
        if (!polygon || !polygon.geometry.coordinates) return;

        const polygonLayer = L.geoJSON(polygon, {
            style: {
                fillColor: '#778d31',
                color: '#778d31',
                weight: 2,
                fillOpacity: 0.5,
            },
        }).addTo(map);

        return () => {
            map.removeLayer(polygonLayer);
        };
    }, [map, polygon]);

    return null;
};

export default MapSourcesRestrictionPolygon;
