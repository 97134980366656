import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { Tile } from '../../interfaces';
import { getTileSearchKey } from './getTileSearchKey';
import { getUrl } from 'aws-amplify/storage';

interface SourceTilesCustomLayerProps {
    tilesSourcesMap: Map<string, string>;
}

const SourceTilesCustomLayer = ({ tilesSourcesMap }: SourceTilesCustomLayerProps) => {
    const map = useMap();

    useEffect(() => {
        const DynamicTileLayer = L.TileLayer.extend({
            createTile(coords: Tile) {
                // Create an empty tile element
                const tile = document.createElement('img');
                tile.alt = '';
                tile.setAttribute('role', 'presentation');

                // Start loading the tile
                (async () => {
                    const key = getTileSearchKey(coords);
                    const source = tilesSourcesMap.get(key);
                    if (!source) {
                        console.error(`Source not found`, coords);
                        return;
                    }

                    const { url } = await getUrl({ path: source });
                    tile.src = url.href;
                })();

                return tile;
            },
        });

        // Create an instance of the custom layer
        const dynamicTileLayer = new DynamicTileLayer();

        // Add the layer to the map
        dynamicTileLayer.addTo(map);
    }, [map]);

    return null;
};

export default SourceTilesCustomLayer;
