import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRoles } from '../interfaces';
import { RootState } from './store';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, AuthUser } from 'aws-amplify/auth';
import config from '../config';
import { getUserRoles } from '../utils/getUserRoles';

// Amplify configuration
Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: config.POOL_DATA.UserPoolId,
            userPoolClientId: config.POOL_DATA.ClientId,
            identityPoolId: config.POOL_DATA.IdentityPoolId,
        },
    },
    Storage: {
        S3: {
            bucket: config.S3_DATA.TilesBucketName,
            region: config.S3_DATA.TilesBucketRegion,
        },
    },
});

export interface UserState {
    user?: AuthUser;
    userRoles?: UserRoles[];
    loading?: boolean;
}

const initialState: UserState = {
    loading: undefined,
};

// Async thunk for getting the current user
export const loadCurrentUser = createAsyncThunk('user/loadCurrentUser', async () => {
    try {
        const currentUser = await getCurrentUser();
        const userRoles = await getUserRoles();
        return { currentUser, userRoles };
    } catch (error) {
        console.error('Error fetching current user:', error);
        throw error;
    }
});

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<AuthUser | undefined>) => {
            state.user = action.payload;
        },
        setUserLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setUserRoles: (state, action: PayloadAction<UserRoles[] | undefined>) => {
            state.userRoles = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCurrentUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(loadCurrentUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.currentUser;
                state.userRoles = action.payload.userRoles;
            })
            .addCase(loadCurrentUser.rejected, (state) => {
                state.loading = false;
            });
    },
});

export const { setUserLoading, setUserRoles, setUser } = UserSlice.actions;

export const selectUserLoading = (state: RootState) => state.user.loading;
export const selectUser = (state: RootState) => state.user.user;
export const selectUserRoles = (state: RootState) => state.user.userRoles;

export default UserSlice.reducer;
