import { LatLng } from '../../interfaces';

interface NavigateToTilesViewerParams {
    polygon?: LatLng[];
    processingEventName?: string;
    processingEventNames?: string[]; // Added to support an array of event names
}

export const navigateToTilesViewer = ({
    polygon,
    processingEventName,
    processingEventNames,
}: NavigateToTilesViewerParams) => {
    const params = new URLSearchParams();

    if (polygon) {
        const polygonParam = encodeURIComponent(JSON.stringify(polygon));
        params.append('polygon', polygonParam);
    }

    if (processingEventName) {
        params.append('processingEventName', encodeURIComponent(processingEventName));
    }

    if (processingEventNames && processingEventNames.length > 0) {
        const processingEventNamesParam = encodeURIComponent(JSON.stringify(processingEventNames));
        params.append('processingEventNames', processingEventNamesParam);
    }

    const url = `/tiles-viewer?${params.toString()}`;
    window.open(url, '_blank');
};
